<template>
    <ion-header>
        <ion-toolbar>
            <ion-row style="justify-content: space-between; align-items: center; flex-wrap: nowrap">
                <ion-col style="margin-left: 18px;">
                    <h4 style="margin: 16px 0;">
                      {{ title }}
                    </h4>
                </ion-col>
                
                <ion-col size="auto">
                    <ion-button
                        fill="clear"
                        @click="closeModalInfo"
                    >
                        <ion-icon
                            slot="icon-only"
                            color="dark"
                            name="close"
                        ></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-toolbar>
    </ion-header>
    <div
        v-if="!isAdmin"
        style="color: #a86700; padding: 0.5rem; width: 100%; cursor:pointer;"
        @click="sendMessageAboutError"
    >
        Нашли ошибку? Напишите нам.
    </div>
    <ion-content>

        <ion-grid v-if="getImages.length">

        <ion-row
            style="justify-content: space-between; flex-wrap: nowrap"
        >

          <ion-col
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-xl="6"
          >
            <Splide>
              <SplideSlide
                  v-for="(image, index) in getImages"
                  :key="index"
              >
                <div class="post__img">
                  <div
                      class="modal-info_blur__bg"
                      :style="{ 'background-image': `url(${ image })` }"
                  >

                  </div>
                  <div
                      class="modal-info_absolute__bg"
                      :style="{ 'background-image': `url(${ image })` }"
                  />
                </div>

              </SplideSlide>
            </Splide>
          </ion-col>


          <ion-col
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-xl="6"
          >
            <ion-card-header>
              <ion-card-subtitle
                  v-if="place"
                  style="display:flex;"
              >
                <div style="margin-right: 0.2rem;">Место:</div>
                <div>{{ place }}</div>
              </ion-card-subtitle>

              <ion-card-subtitle
                  v-if="isShowCoords"
                  style="display:flex;"
              >
                <div style="margin-right: 0.2rem;">Координаты:</div>
                <div>{{ coords.join(', ') }}</div>
              </ion-card-subtitle>

              <ion-card-subtitle
                  v-if="company.name"
                  style="display:flex; flex-direction: column; border-top: 1px solid rgba(0, 0, 0, 0.1); padding-top: 30px; margin-top: 30px;"
              >
                <div style="font-weight: bold">
                  {{ company.name }}
                  <span v-if="company.inn"> / {{ company.inn }}</span>
                </div>

                <div
                    v-if="company.desc"
                    style="padding-top: 5px; white-space: break-spaces"
                >
                  {{ company.desc }}
                </div>
              </ion-card-subtitle>
            </ion-card-header>
          </ion-col>
        </ion-row>

      </ion-grid>

        <ion-row v-else>

        <ion-col>
          <ion-card-header>
            <ion-card-subtitle
                v-if="place"
                style="display:flex;"
            >
              <div style="margin-right: 0.2rem;">Место:</div>
              <div>{{ place }}</div>
            </ion-card-subtitle>

            <ion-card-subtitle
                v-if="isShowCoords"
                style="display:flex;"
            >
              <div style="margin-right: 0.2rem;">Координаты:</div>
              <div>{{ coords.join(', ') }}</div>
            </ion-card-subtitle>

            <ion-card-subtitle
                v-if="company.name"
                style="display:flex; flex-direction: column; border-top: 1px solid rgba(0, 0, 0, 0.1); padding-top: 30px; margin-top: 30px;"
            >
              <div style="font-weight: bold">
                {{ company.name }}
                <span v-if="company.inn"> / {{ company.inn }}</span>
              </div>

              <div
                  v-if="company.desc"
                  style="padding-top: 5px; white-space: break-spaces"
              >
                {{ company.desc }}
              </div>
            </ion-card-subtitle>
          </ion-card-header>
        </ion-col>

      </ion-row>

        <ion-card-header>
            <ion-card-title style="display:flex; align-items: center;  padding-top: 5px;">
                <b>{{ name }}</b>

                <a
                    v-if="passport"
                    :href="passport"
                    class="tooltip"
                    style="margin-left: 1rem;"
                    target="_blank"
                >
                    <ion-icon
                        slot="icon-only"
                        name="document"
                        style="font-size: 1.4rem; color: forestgreen;"
                    ></ion-icon>

                    <span
                        class="tooltiptext"
                        style="margin-left: 30px;"
                    >Паспорт маршрута
                    </span>
                </a>
            </ion-card-title>
        </ion-card-header>

        <ion-card-content style="white-space: break-spaces;" v-html="urlDetected"/>

        <ion-card-content v-if="thisIsRoute && !isAdmin">
          <div
              v-if="!isCommentSent || commentsData.length "
              style="font-weight: bold; font-size: 1.25rem;"
              :class="{'ion-margin-bottom': isCommentSent}"
          >
              Комментарии
          </div>

          <div
              v-if="!isCommentSent"
              style="margin-bottom: 1.5rem;"
          >
              <InputDefault
                  v-model.trim="commentValue"
                  auto-grow
                  placeholder="Напишите свой комментарий о маршруте..."
              />

              <ReCaptcha
                  style="margin-top: -10px; margin-bottom: 10px;"
                  @verify="isRecaptchaVerified = true;"
                  @expire="isRecaptchaVerified = false;"
                  @error="isRecaptchaVerified = false;"
              />

              <ion-button
                  :disabled="disableSendButton"
                  fill="solid"
                  @click="sendPointComment"
              >
                  Отправить комментарий
              </ion-button>
          </div>

          <template v-if="commentsData.length">
            <ion-row
                v-for="(comment, index) in commentsData"
                :key="index"
            >
              <ion-item
                  v-if="comment.comment"
                  class="ion-margin-bottom"
                  style="width: 100%;"
              >
                <div style="width: 100%; padding: 10px 0;">
                  <div style="margin-bottom: 8px;">
                      {{ comment.comment }}
                  </div>
                  <div v-if="comment.publishDate">
                      Опубликован: {{ formattingDate(comment.publishDate) }}
                  </div>
                </div>
              </ion-item>
            </ion-row>
          </template>
        </ion-card-content>
    </ion-content>
</template>

<script>
import {
  computed, ref,
  toRefs,
} from 'vue';
import {
    Splide,
    SplideSlide,
} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/dist/css/splide.min.css';
import {IonCardContent } from "@ionic/vue";
import InputDefault from "./InputDefault";
import dateFormat from '../scripts/date_format';
import axios from 'axios';
import ReCaptcha from "@/components/ReCaptcha.vue";

export default {
    name: 'ModalPointInfo',
    
    components: {
      ReCaptcha,
      InputDefault,
      IonCardContent,
      Splide,
      SplideSlide,
    },
    
    props: {
        images: {
            type: Array,
            default() {
                return [];
            },
        },
        
        disableImage: {
            type:    Boolean,
            default: false,
        },
        
        title: {
            type:     String,
            default:  '',
            required: true,
        },
        
        desc: {
            type:     String,
            default:  '',
            required: true,
        },
        
        name: {
            type:     String,
            default:  '',
            required: true,
        },
        
        place: {
            type:    String,
            default: '',
        },
        
        passport: {
            type:    String,
            default: '',
        },
        
        coords: {
            type:    Array,
            default: () => [],
        },
        
        company: {
            type:    Object,
            default: () => ({
                name: '',
                desc: '',
                inn:  '',
            }),
        },

        id: {
            type: String,
            default: null
        },

        isAdmin: {
            type:    Boolean,
            default: false,
        }
    },

    emits: ['close'],
    
    setup(props) {
        const { images, place, coords } = toRefs(props);
        const isCommentSent = ref(false);
        const commentValue = ref('');
        const commentsData = ref([]);
        const recaptchaWidget = ref(null);
        const isRecaptchaVerified = ref(false);

        // const imageUrl = computed(() => image.value ? image.value : '../assets/images/no-image-large.jpg');
        // const imageUrl = computed(() => image.value ? image.value : null);
        const getImages = computed(() => {
            if (!images.value) {
                return [];
            }
            else if (Object.prototype.toString.call(images.value).slice(8, -1) === 'String') {
                return [ images.value ];
            }
            else {
                return images.value;
            }
        });
        
        const isShowCoords = computed(() => {
            return place.value
                || (Object.prototype.toString.call(coords.value).slice(8, -1) === 'Array' && coords.value.length > 0)
                || (Object.prototype.toString.call(coords.value).slice(8, -1) === 'String' && coords.value);
        });

        const disableSendButton = computed(() => {
            return !commentValue.value.length || !isRecaptchaVerified.value;
        });

        const urlDetected = computed(() => {
          const detectUrl = /(https?:[а-яa-zА-Я-A-Z./0-9%?=&-_+)(*$#@!^:]{1,500})((?=(^https?)))?/g;
          const splitUrl = /(\B|[/])(?=(http[s])+(?!\d))/g;

          const deleteSplitUrlText = props.desc.replace(splitUrl, " ");

          return deleteSplitUrlText.replace(detectUrl, function (url){
            return '<a href="' + url + '" target="_blank" >'+ url +' </a>'
          })
        });

        const thisIsRoute = computed(() => {
          return /^[Мм]аршруты?/gm.test(props.name)
        });

      return {
            // imageUrl:     imageUrl,
            getImages:    getImages,
            isShowCoords: isShowCoords,
            urlDetected:  urlDetected,
            thisIsRoute:  thisIsRoute,

            commentValue: commentValue,
            commentsData: commentsData,
            isCommentSent: isCommentSent,
            recaptchaWidget: recaptchaWidget,
            isRecaptchaVerified: isRecaptchaVerified,
            disableSendButton:   disableSendButton,
        };
    },
    
    methods: {
        formattingDate: dateFormat.formatHumanDateWithoutTime,

        closeModalInfo() {
            this.$emit('close');
        },
        
        sendMessageAboutError() {
            this.$error('Функционал находится в разработке');
        },

        async sendPointComment() {
            const request = {
                objectId:     this.id,
                comment:      this.commentValue,
            };
            try {
                const response = await axios.post('/api/comments/add', request);
                if (response.data.data.success) {
                    this.isCommentSent = true;
                    this.$success('Комментарий успешно отправлен на верификацию');
                }
            }
            catch(error) {
                console.error(error);
                console.error('ERROR SEND COMMENT');
                this.$error('Не удалось отправить комментарий. Попробуйте позже или обратитесь к администратору');
            }
        },

        async getPointComments() {
            const request = {
                objectId: this.id,
            };
            try {
                const response = await axios.post('/api/comments/show', request);
                this.commentsData = response.data.data;
            }
            catch(error) {
                console.error(error);
                console.error('ERROR GET COMMENTS;');
            }
        },
    },

    async mounted() {
      await this.getPointComments();
    },
};
</script>

<style>
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: #d5d5d5;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 0.9rem;
    margin-left: 30px;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    
    top: 0;
    left: 0;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.post__img {
  position: relative;
  height: 350px;
}

.modal-info_blur__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(8px);
}

.modal-info_absolute__bg {
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 110%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

</style>
