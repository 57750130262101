<template>
  <div
      id="g-recaptcha"
      class="g-recaptcha"
  />
</template>

<script setup>
import {onMounted} from 'vue';

const emit = defineEmits(['verify', 'expire', 'error']);

const siteKey = "6LfKarojAAAAAMU3WgT9hnisjH3liVgLf3y0u4zK";

function render () {
  if (window.grecaptcha) {
    window.grecaptcha.render('g-recaptcha', {
      sitekey: siteKey,
      size: 'normal',
      callback:           () => emit('verify'),
      'expired-callback': () => emit('expire'),
      'error-callback':   () => emit('error'),
    });
  }
}

onMounted (() => {
  render();
});
</script>

<style scoped>

</style>