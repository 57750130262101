<template>
    <ion-item
        :class="generateClasses"
        button
        detail
        style="cursor: pointer;"
        @click="$emit('clickGetPlacemark')"
    >
        <ion-label>
            <h3
                v-if="placemark.isEvent"
                style="margin-bottom: 0.2rem; border: 1px dotted rgba(0, 0, 0, 0.4); display: inline-block; padding: 0 10px 0 3px; border-radius: 10px; background-color: rgba(255,165,0,0.66);"
            >
                Событие
            </h3>

          <h3
              v-if="isAdmin && isConfirmedPlacemark"
              style="margin-bottom: 0.2rem; margin-right: 8px; border: 1px dotted rgba(0, 0, 0, 0.4); display: inline-block; padding: 0 5px; border-radius: 10px; background-color: #2dd36f;"
          >
            <ion-text color="light">
                Подтвержден
            </ion-text>
          </h3>

          <h3
              v-if="isAdmin && isRejectedPlacemark"
              style="margin-bottom: 0.2rem; border: 1px dotted rgba(0, 0, 0, 0.4); display: inline-block; padding: 0 5px; border-radius: 10px; background-color: #eb445a;"
          >
            <ion-text color="light">
                Отклонен
            </ion-text>
          </h3>

            <h2>
                <slot name="title">
                  {{ ordered ? `${placemarkIndex + 1}.` : '' }}
                  {{ placemark.name }}
                </slot>
            </h2>
            <h3>{{ placemark.place }}</h3>
            <p>{{ placemark.desc }}</p>

            <div
                v-if="isAdmin"
                style="display: flex; flex-wrap: wrap; margin-top: 4px;"
            >
              <div
                  v-if="!isConfirmedPlacemark"
                  style="margin-right: 8px;"
              >
                <ion-button
                    fill="solid"
                    @click="$emit('clickConfirmButton')"
                >
                  Подтвердить
                </ion-button>
              </div>

              <div v-if="!isRejectedPlacemark">
                <ion-button
                    color="danger"
                    @click="$emit('clickDiscardButton')"
                >
                  Отклонить
                </ion-button>
              </div>
            </div>
        </ion-label>

        <template v-if="editable">
          <ion-button
              fill="clear"
              @click="$emit('clickEditButton')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24">
              <path d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"/>
            </svg>
          </ion-button>

          <ion-button
              fill="clear"
              @click="$emit('clickDeleteButton')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="red" width="22" height="22">
              <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" fill="red"/>
            </svg>
          </ion-button>
        </template>

        <ion-button
            fill="clear"
            @click="$emit('clickInfoButton')"
        >
            <img
                slot="icon-only"
                alt="Информация"
                height="25"
                src="../../public/assets/images/info.svg"
                width="25"
                style="background-color: #fff; border-radius: 50%; margin: 20px 0;"
            >
        </ion-button>
    </ion-item>
</template>

<script>
import {
    computed,
    toRefs,
} from 'vue';
import {CONSTANTS} from "@/boot/constants";

export default {
    name:  'MapItemsListItem',
    props: {
        placemark: {
            type:    Object,
            default: () => ({
                name:     '',
                place:    '',
                desc:     '',
                isActive: false,
            }),
        },
      placemarkIndex: {
          type: Number,
          default: 0,
      },
      editable: {
          type:    Boolean,
          default: false,
      },
      ordered: {
          type:    Boolean,
          default: false,
      },
        isAdmin: {
            type:    Boolean,
            default: false,
        },
    },
    emits: ['clickGetPlacemark', 'clickConfirmButton', 'clickDiscardButton', 'clickInfoButton'],

    setup(props) {
        const { placemark } = toRefs(props);
        const generateClasses = computed(() => ({
            'is-active': placemark.value.isActive,
        }));
        const isConfirmedPlacemark = computed(() => {
            return placemark.value.status === CONSTANTS.ROUTE_STATUSES.CONFIRMED;
        });
        const isRejectedPlacemark = computed(() => {
            return placemark.value.status === CONSTANTS.ROUTE_STATUSES.REJECTED;
        });
        
        return {
            generateClasses:      generateClasses,
            isConfirmedPlacemark: isConfirmedPlacemark,
            isRejectedPlacemark:  isRejectedPlacemark,
        };
    },
};
</script>

<style scoped>
ion-item.is-active {
    --background: rgb(255, 196, 87, 0.3);
}
</style>
