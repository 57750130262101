<template>
    <ion-header class="header-desktop">
        <ion-toolbar>
            <ion-row style="justify-content: space-between; align-items: center; flex-wrap: nowrap">
                <ion-col>
                    <h4>
                        <slot name="title"/>
                    </h4>
                </ion-col>
                
                <ion-col size="auto">
                    <ion-button
                        fill="clear"
                        @click="closeModal"
                    >
                        <ion-icon
                            slot="icon-only"
                            color="dark"
                            name="close"
                        ></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-toolbar>
    </ion-header>
    <div
        v-if="!disableAddButtons"
        class="buttonAddWrapper"
        style="display: flex; flex-direction: row; justify-content: space-between;"
    >
        <div
            class="buttonAdd"
            style="color: #3880ff; flex: 1 1 auto"
            @click="$emit('openAddPoint')"
        >
            <slot name="add-title"/>
        </div>
        <div
            v-if="activeSecondButton"
            class="buttonAdd"
            style="color: #3880ff;"
            @click="$emit('openAddEvent')"
        >
            <slot name="add-event-title"/>
        </div>
    </div>
    <ion-content>
        <MapItemsList
            :placemarks="placemarks"
            :is-admin="isAdmin"
            @getPlacemark="placemarkIndex => $emit('getPlacemark', placemarkIndex)"
            @openModalInfo="placemark => $emit('openModalInfo', placemark)"
            @confirmPlacemark="index => $emit('confirmPlacemark', index)"
            @discardPlacemark="index => $emit('discardPlacemark', index)"
        >
            <template v-slot:map-item-title="slots">
                <slot
                    :placemarkName="slots.placemarkName"
                    :placemarkIndex="slots.placemarkIndex"
                    name="map-list-item-title"
                />
            </template>
            
            <template v-slot:empty-list-message>
                <slot name="empty-list-message"/>
            </template>
        </MapItemsList>
    </ion-content>
</template>

<script>
import {
    defineComponent,
} from 'vue';
import MapItemsList from './MapItemsList';

export default defineComponent({
    name:       'MapItemsListDialog',
    components: {
        MapItemsList,
    },
    
    props: {
        placemarks: {
            type:    Array,
            default: () => [],
        },
        
        activeSecondButton: {
            type:    Boolean,
            default: false,
        },
        
        disableAddButtons: {
            type:    Boolean,
            default: false,
        },

        isAdmin: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['close', 'confirmPlacemark', 'discardPlacemark'],
    
    methods: {
        async closeModal() {
            this.$emit('close');
        },
    },
});
</script>
