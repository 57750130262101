<template>
    <ion-list>
        <MapItemsListItem
            v-for="(placemark, index) in placemarks"
            :key="index"
            :editable="editable"
            :ordered="ordered"
            :placemark="placemark"
            :is-admin="isAdmin"
            :placemarkIndex="index"
            @clickEditButton="_ => $emit('editInList', index)"
            @clickDeleteButton="_ => $emit('deleteFromList', index)"
            @clickGetPlacemark="_ => $emit('getPlacemark', index)"
            @clickInfoButton="_ => clickInfoButton(placemark)"
            @clickConfirmButton="_ => $emit('confirmPlacemark', index)"
            @clickDiscardButton="_ => $emit('discardPlacemark', index)"
        >
            <template v-slot:title>
                <slot
                    :placemarkName="placemark.name"
                    :placemarkIndex="index"
                    name="map-item-title"
                />
            </template>
        </MapItemsListItem>
        
        <ion-item
            v-if="placemarks.length === 0"
            style="cursor: pointer;"
        >
            <ion-label class="ion-activatable ripple-parent">
                <p style="overflow: visible; text-overflow: initial; white-space: normal">
                    <slot name="empty-list-message"/>
                </p>
                <ion-ripple-effect></ion-ripple-effect>
            </ion-label>
        </ion-item>
    </ion-list>
</template>

<script>
import MapItemsListItem from './MapItemsListItem';

export default {
    name:       'MapItemsList',
    components: { MapItemsListItem },
    props:      {
        placemarks: {
            type:    Array,
            default: () => [],
        },
        editable: {
          type:    Boolean,
          default: false,
        },
        ordered: {
          type:    Boolean,
          default: false,
        },
        isAdmin: {
            type:    Boolean,
            default: false,
        },
    },
    methods:    {
        clickInfoButton(placemark) {
            if (placemark.isEvent) {
                this.$emit('openModalEventInfo', placemark);
            }
            else {
                this.$emit('openModalInfo', placemark);
            }
        },
    },
};
</script>

<style scoped>
</style>
